.etLogin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-background-color);
  box-shadow: 0px 2px 22px 4px var(--light-porcelain);
  width: 450px;
  padding: 35px 40px 40px;
  margin: 80px auto 0;
  border-radius: var(--border-radius-default);
  &Controls {
    display: flex;
    flex-direction: column;
    width: var(--three-columns-width);
    margin: 16px auto;

    > div {
      margin-bottom: 16px;
    }
  }
  &BottomLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > button {
      min-width: auto;
    }
  }
  &ForgotWrapper {
    position: relative;
  }
  &ForgotButton {
    &.btnCustomized {
      position: absolute;
      top: 0;
      right: 0;
      min-width: unset;
    }
  }
  &Button {
    margin-top: 16px;
    align-self: flex-end;
  }

  &Input {
    width: var(--three-columns-width);
  }
}
