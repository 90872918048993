.header {
  text-align: center;
  width: 300px;
  margin-top: 25px;
}

.description {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--rolling-stone-dark);
  text-align: center;
}

.link {
  margin-top: auto;
  font-size: 16px;
  color: var(--rolling-stone-dark);
}
