.etForgotPassword {
  &Description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--rolling-stone-dark);
    margin: 0;
  }
  &Input {
    width: var(--three-columns-width);
  }
  &Controls {
    display: flex;
    flex-direction: column;
    width: var(--three-columns-width);
    margin: 24px auto 8px auto;
    > div {
      margin-bottom: 16px;
    }
  }
  &BottomLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0 0 0;
    & > p {
      font-size: 16px;
      line-height: 24px;
      color: var(--rolling-stone-dark);
    }
    & > a {
      min-width: unset;
      padding: 0 8px;
    }
  }
  &EndDescription {
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: var(--rolling-stone-dark);
    & > p {
      font-weight: bold;
      color: var(--abbey);
      display: inline;
    }
  }
}
