.title {
  margin-top: 40px;
  text-align: center;
}

.description {
  margin-bottom: auto;
  font-size: 16px;
  line-height: 24px;
  color: var(--rolling-stone-dark);
  text-align: center;
  word-break: break-word;
}
