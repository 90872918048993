.switcherButton {
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: white;

  &:not([disabled]) {
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: var(--body-background-color);
    }
  }

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  &Content {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--rolling-stone-dark);
    font-size: 12px;

    & > span {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    & > *:last-child {
      font-size: 14px;
      font-weight: bold;
      color: var(--shark);
    }
  }
}

.switcherTooltip {
  min-width: 300px;
  max-height: 500px;
  overflow: auto;
  padding: 0;
  border-radius: 2px;
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background-color: white !important;

  :global(.tippy-content) {
    padding: 0;
  }
}

.optionsContainer {
  margin: 0;
  padding: 0;
}

.selected {
  background-color: var(--body-background-color);
}
