.header {
  margin-bottom: 32px;
  text-align: center;
  word-break: break-word;
}

.companiesMenu {
  &Container {
    width: 100%;
    padding: 0 7px;
    margin-bottom: 12px;
    &ListName {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: var(--rolling-stone-dark);
      margin-bottom: 12px;
      display: inline-block;
    }
  }
  &InvitationsHeader {
    width: 100%;
    padding: 0 7px;
    font-size: 12px;
    font-weight: 600;
    color: var(--rolling-stone-dark);
    text-align: left;
    text-transform: uppercase;
  }
}
