.etCreatePassword {
  &HiddenInput {
    display: none;
  }
  &Description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--rolling-stone-dark);
    margin: 0;
  }
  &Info {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: var(--shark);
  }
  &BottomLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > button {
      min-width: auto;
    }
  }

  &Input {
    width: var(--three-columns-width);
  }
  &Controls {
    display: flex;
    flex-direction: column;
    width: var(--three-columns-width);
    margin: 24px auto 8px auto;

    > div {
      margin-bottom: 16px;
    }
  }
}
