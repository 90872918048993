.etLoginRequirements {
  background: var(--selago);
  border-radius: 2px;
  width: 362px;
  padding: 12px;
  margin-bottom: 16px;
  & > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--shark);
  }
  & > ul {
    list-style-type: none;
    margin: 4px 0 0;
    padding-left: 0;
    & > li {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: var(--shark);
      &:before {
        content: "-";
        display: inline;
        margin-right: 5px;
      }
    }
  }
}
