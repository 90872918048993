.companyLink {
  &Container {
    position: relative;
    width: 100%;
    padding: 14px 16px 12px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--porcelain);
    border-radius: 2px;
    background-color: transparent;
    transition-duration: 0.2s;
    cursor: pointer;
    margin-top: -1px;

    &:hover,
    &:focus {
      background-color: var(--body-background-color);
    }
  }

  &Content {
    max-width: 100%;
    min-width: 250px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--rolling-stone-dark);
    font-size: 14px;
    line-height: 20px;

    & > span {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    :last-child {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: var(--shark);
      overflow: hidden;
    }

    &IsInvited :last-child {
      text-align: left;
      max-width: 250px;
      color: var(--rolling-stone-dark);
    }
  }

  &Icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--forest-green);
    & > *:not(:first-child) {
      margin-left: 12px;
      font-size: 14px;
    }
  }

  &Loader {
    padding-right: 26px;
    justify-content: flex-end;
    align-items: center;
  }
}

.dropdownView {
  &.companyLinkContainer {
    padding: 8px 12px;
    border: none;
  }
  & .companyLinkContent {
    font-size: 12px;
    & > *:last-child {
      font-size: 14px;
    }
  }
  & .companyLinkLoader {
    padding-right: 12px;
    justify-content: flex-end;
    align-items: center;
  }
}
