.userProfile {
  display: flex;
  align-items: center;

  &Name {
    margin-right: 8px;
  }

  & > .userProfileLogoutButton {
    background-color: transparent;
    color: var(--rolling-stone-dark);

    &:hover {
      color: var(--forest-green)
    }
  }
}
